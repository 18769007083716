<script>
import LoadingFull from "@/components/loading/LoadingFull.vue";
import LoadingPart from "@/components/loading/LoadingPart.vue";
export default {
  components: {
    LoadingFull,
    LoadingPart,
  },
  beforeCreate(){
    this.$store.state.tlsError = '';
    this.$store.state.vfsError = '';
    this.$store.state.aisError = '';
    this.$store.state.blsError = '';
    this.$store.state.loadingFull = false;
    this.$store.state.loadingPart = false;
  }
}
</script>

<template>
  <div id="app">
    <router-view />

    <loading-full v-if="$store.state.loadingFull"></loading-full>
    <loading-part v-if="$store.state.loadingPart"></loading-part>
  </div>
</template>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  min-height: 100vh;
  background-color: #222222;
}
</style>
